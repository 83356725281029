.ContentImageBlockText {
  @apply mb-6 text-lg font-normal leading-[1.333rem] space-y-2;
}

.ContentImageBlockText > p {
  @apply !mb-6;
}

.ContentImageBlockText li {
  @apply list-none relative ml-8 py-1;
}
