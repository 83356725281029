.DoctorQuote::before {
  @apply absolute block top-4 left-6 w-10 h-10 z-10 md:-top-10 md:-left-20;
  content: "";
  transform: rotate(180deg);
  background: url("/images/doublequotes.svg") no-repeat;
}

.DoctorQuote::after {
  @apply absolute block bottom-12 right-6 w-10 h-10 md:-bottom-12 md:-right-16;
  content: "";
  background: url("/images/doublequotes.svg") no-repeat;
}

.DoctorQuote_Dark::before {
  @apply absolute block top-4 left-6 w-10 h-10 z-10 md:-top-10 md:-left-20;
  content: "";
  transform: rotate(180deg);
  background: url("/images/doublequotes_white.svg") no-repeat;
}

.DoctorQuote_Dark::after {
  @apply absolute block bottom-12 right-6 w-10 h-10 md:-bottom-12 md:-right-16;
  content: "";
  background: url("/images/doublequotes_white.svg") no-repeat;
}
