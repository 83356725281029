.swiperContainer::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 100%;
  z-index: 40;
  background: linear-gradient(90deg, rgba(23, 24, 26, 0) 0%, #17181a 100%);
  pointer-events: none;
}
